import React, { Component } from 'react'
import LearningPage from '../../components/LearningPage/LearningPage'

class LearningRoute extends Component {
  render() {
    return (
      <main>
        <LearningPage />
      </main>
    );
  }
}

export default LearningRoute
